import React, { FC } from 'react';
import { DoublePanelsType } from '../../../models/double-panels-type';
import ButtonSecondary from '../../atoms/button-secondary/button-secondary';
import IcomoonIcon from '../../atoms/icomoon-icon/icomoon-icon';
import TextLink from '../../atoms/text-link/text-link';

const DoublePanels: FC<DoublePanelsType> = ({
  emailAddress,
  panel1CallToAction,
  panel1Description,
  panel1Heading,
  panel1Highlights,
  panel2Description,
  panel2Heading,
  panel2PhoneNumber,
  panel2SubHeading1,
  panel2SubHeading2,
}: DoublePanelsType) => {
  return (
    <div className="py-component-spacing-mobile md:py-component-spacing grid grid-cols-1 lg:grid-cols-2 gap-3">
      <div className="bg-light-blue rounded-lg px-6 py-12 md:p-16 flex flex-col items-center text-center">
        {panel1Heading && (
          <h4 className="page-heading-four">{panel1Heading}</h4>
        )}
        {panel1Description && (
          <p className="mt-4 text-grey-600 text-center">{panel1Description}</p>
        )}
        {panel1Highlights?.length && (
          <ul className="mt-8 grid gap-3">
            {panel1Highlights.map((highlight) => (
              <li key={highlight} className="flex">
                <IcomoonIcon
                  name="check-circle"
                  color="turquoise"
                  className="mr-4"
                />
                <span className="font-semibold">{highlight}</span>
              </li>
            ))}
          </ul>
        )}
        {panel1CallToAction && (
          <div className="mt-8">
            <ButtonSecondary color="turquoise" {...panel1CallToAction} />
          </div>
        )}
      </div>
      <div className="bg-floral rounded-lg px-6 py-12 md:p-16 flex flex-col items-center text-center">
        {panel2Heading && (
          <h4 className="page-heading-four">{panel2Heading}</h4>
        )}
        {panel2Description && (
          <p className="mt-4 text-grey-600 text-center">{panel2Description}</p>
        )}
        <h6 className="mt-8 page-heading-six">{panel2SubHeading1}</h6>
        <TextLink
          name={panel2PhoneNumber}
          textSize="lg"
          url={`tel:${panel2PhoneNumber}`}
        />
        <div className="mt-8 w-24 border-b border-grey-200" />
        <h6 className="mt-8 page-heading-six">{panel2SubHeading2}</h6>
        <TextLink
          name={emailAddress}
          textSize="lg"
          url={`mailto:${emailAddress}`}
        />
      </div>
    </div>
  );
};

export default DoublePanels;
